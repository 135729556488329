import { IconCloseSmall, IconDownload, IconOpenInNew } from 'icons'
import { ComponentProps, FC, KeyboardEventHandler, useEffect, useRef } from 'react'
import { useNavigation } from 'react-router-dom'
import { IconButton, Portal, Tooltip, useNavigateOut } from 'ui'
import { Backdrop } from 'ui/backdrop'
import { cn } from 'utils'
import { downloadObjectURL } from 'utils/download-object-url'
import styles from './pdf-dialog.module.scss'
import { PdfViewer } from '../pdf-viewer'

type Props = ComponentProps<typeof PdfViewer> & {
  renderPdfContent?: (props: ComponentProps<typeof PdfViewer>) => JSX.Element
  hideDownload?: boolean
  hideOpen?: boolean
  downloadFileName?: string
  dialogClassName?: string
  preventBackdropClickClose?: boolean
}

export const PdfDialog: FC<Props> = ({
  pdf,
  renderPdfContent = renderPdfContentDefault,
  hideDownload,
  hideOpen,
  downloadFileName,
  dialogClassName,
  preventBackdropClickClose,
  ...props
}) => {
  const close = useNavigateOut(undefined, { revalidate: false })
  const { state } = useNavigation()

  const closeButtonRef = useRef<HTMLButtonElement>(null)
  const handleKeyUp: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.defaultPrevented) return
    if (event.key === 'Escape') {
      event.preventDefault()
      event.stopPropagation()
      close()
    }
  }
  const preventClick = (event: React.MouseEvent) => event.stopPropagation()
  useEffect(() => closeButtonRef.current?.focus(), [])

  return (
    <Portal scrollLock rootId={styles.pdfDialog}>
      <Backdrop
        className={styles.backdrop}
        onClick={preventBackdropClickClose ? undefined : close}
      />
      <div className={styles.actions}>
        {!hideDownload && (
          <IconButton
            className={styles.download}
            data-tooltip-id={Tooltip.ID}
            data-tooltip-content="Download"
            onClick={() => downloadObjectURL(URL.createObjectURL(pdf), downloadFileName)}
          >
            <IconDownload />
          </IconButton>
        )}
        {!hideOpen && (
          <IconButton
            className={styles.open}
            data-tooltip-id={Tooltip.ID}
            data-tooltip-content="Open in New Tab"
            onClick={() => window.open(URL.createObjectURL(pdf), '_blank')}
          >
            <IconOpenInNew />
          </IconButton>
        )}
        <IconButton
          className={styles.close}
          ref={closeButtonRef}
          data-tooltip-id={Tooltip.ID}
          data-tooltip-content="Close"
          onClick={close}
        >
          <IconCloseSmall />
        </IconButton>
      </div>
      <div className={cn(styles.dialog, dialogClassName)} onKeyUp={handleKeyUp}>
        <section className={styles.content} onClick={preventClick}>
          {renderPdfContent({
            pdf,
            className: styles.pdf,
            pageClassName: cn(styles.page, state !== 'idle' && styles.closing),
            renderTextLayer: true,
            ...props,
          })}
        </section>
      </div>
    </Portal>
  )
}

const renderPdfContentDefault = (props: ComponentProps<typeof PdfViewer>) => (
  <PdfViewer {...props} />
)
